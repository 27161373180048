import apiTool from '@/command/apiTool'
import { Button } from 'ant-design-vue'
import DrawerForm from '@/components/DrawerForm'

function onOpen({ update, records = {} }) {
  let dataSource = JSON.parse(JSON.stringify(records))
  apiTool.showDrawer({
    title: '编辑',
    formData: dataSource,
    width: '600px',
    form: [
      {
        name: '大图标',
        type: 'iconUpload',
        typeData: [
          {
            key: 'image2',
            desc: '@2x   xhdpi <br/>上传尺寸112*40px'
          },
        ]
      },
      {
        name: '小图标',
        type: 'iconUpload',
        typeData: [
          {
            key: 'image22',
            desc: '@2x   xhdpi <br/>上传尺寸68*24px'
          },
        ]
      }
    ],
    success({ data, setHidden }) {
      update(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

export default function() {
  const { update } = arguments[0]
  return {
    type: 'table',
    url: '/config/farmVipInfo/list',
    edit: '/config/farmVipInfo/update',
    props: {
      columns: [
        {
          dataIndex: 'title',
          type: 'lt-200',
          customRender: function customRender(text, records, index, h) {
            return (
              <div>
                <img
                  src={records.image2 || records.image3}
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                />
                <span>{'等级' + index}</span>
              </div>
            )
          }
        },
        {
          dataIndex: 'a2',
          type: 'rt-100',
          customRender: function customRender(text, records, index, h) {
            return (
              <Button ghost={true} type="primary" onClick={() => onOpen({ update, records })}>
                编辑
              </Button>
            )
          }
        }
      ]
    }
  }
}
